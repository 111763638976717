<template>
  <span
    class="k-icon k-i-loading loading-indicator loading-indicator--small"
  />
</template>

<script>
export default {
  name: 'WaitingIndicator'
}
</script>
